<template>
  <div class="caseinfo">
     <div class="content_breadcrumb">
      <div class="content_nav">
        当前位置&nbsp;： 新闻资讯 &nbsp;&nbsp; >&nbsp;&nbsp;
        <span class="content_detail">详细内容</span>
      </div>
    </div>
    <div class="cotainer">
      <div class="header">
        <h1>昆阳路越江及配套道路工程</h1>
        <p>时间：2019-2.21</p>
      </div>
      <div class="introduce mt_30">
        <p>建设单位：上海城投（集团）有限公司</p>
        <p>施工单位：隧道股份上海公路桥梁（集团）有限公司</p>
        <p>监理单位：中铁武汉大桥工程咨询监理有限公司</p>
      </div>
      <div class="introduce mt_30">
        <h2>项目介绍</h2>
        <p>1)昆阳路黄浦江大桥，拓宽改建昆阳路（江川路-东川路），利用桥下空间建设管理用房，同步迁建巨潮港锚地，实施地面辅助道路与桥梁、人非越江设施、排水、绿化、照明、道路与航道安全设施等附属工程。</p>
        <p>2)昆阳路越江及配套道路工程位于上海市闵行区、奉贤区交界处，跨越黄浦江，北连闵行区昆阳路，可达中心城区、虹桥枢纽和浦东机场；南接奉贤区浦卫公路，可达南桥新城、金山区和浙江省。</p>
       </div>
      <div class="infokey mt_30">
        <h2>项目应用</h2>
        <h3>权限配置</h3>
        <p>一、组织信息管理是企业的部门、岗位、用户及角色的权限配置区。</p>
        <p>二、用户是平台的使用者，角色可以与用户进行关联，用户中可设置部门及岗位，完成权限分配，以达到不同权限人员，对项目模块操作的权限不同（如查询，增加，删除，修改）。</p>
        <p>三、本系统开发采用基于角色的工作流访问控制模型，即根据工作流需求设置统一的角色集，并分配角色相应的访问权限，再将角色与用户相关联，旨在实现用户与访问权限的逻辑分离，更符合桥梁工程建设中用户、角色和部门的应用特征。</p>
        <div class="pic"><img src="../../assets/image/cases/case_bj(1)_kyl.png" alt=""></div>
        <h3>综合监控</h3>
        <p>一、平台应集中直观展示纳管工程项目分布、状态及项目概况，方便监管部门随时掌握项目整体状态，“综合监控”模块是进入施工管理平台的首页，也是管理者能够第一眼就能掌握项目概况的一页。</p>
        <p>二、对于工作人员的工作时长、进度等信息通过大数据可视化，对监管项目进行项目统计和分项统计，以 3D 图形直观展示，以便管理者制定相应的对策，实行差异化管理，对于非结构化的信息数据，则通过文字信息展示，如环境监测、今日危险源等。</p>
        <div class="pic"><img src="../../assets/image/cases/case_bj(1)_kyl2.png" alt=""></div>
        <h3>智慧工地</h3>
        <h4>1.视频监控</h4>
        <p>视频监控已经是项目现场必不可少的一个实时可视化监控的窗口，为了减少多平台管理的复杂性，我们将视频监控的平台数据接入到施工管理平台的全景展现模块。在全景展现的模型中找到摄像头安装的位置，然后在模型中增加锚点，点击锚点即可查看视频。</p>
        <h4>2.人员定位</h4>
        <p>1).通过架设UWB基站实现对施工人员的三维定位，管理人员可对人员位置在“全景展现“中实时监控，通过查看工人的历史轨迹和闸机进出状况综合实现对人员的考勤管理；</p>
        <p>2).通过在模型中绘制电子围栏的方式实现对人员的安全管理。</p>
        <p>3).一旦发现人员定位超过安全围栏可初步判断该人员已离开安全区域，再结合全景展现中的视频监控进一步确认，即可实现对人员作业区域的可视化便捷管理。</p>
        <div class="pic"><img src="../../assets/image/cases/case_bj(1)_kyl3.png" alt=""></div>
        <h4>3.环境监测</h4>
        <p>为确保施工工程中，不影响周边居民生活环境及自然环境，本项目严格按照环境保护规定，在现场安装环境监测设备，与平台数据对接，实时反映施工区域内的环境情况，为监控环境情况做好预防措施。</p>
        <div class="pic"><img src="../../assets/image/cases/case_bj(1)_kyl4.png" alt=""></div>
        <div class="pic"><img src="../../assets/image/cases/case_bj(1)_kyl5.jpg" alt=""></div>
        <div class="pic"><img src="../../assets/image/cases/case_bj(1)_kyl6.jpg" alt=""></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted () {
      document.querySelector('.nav').style.backgroundColor = '#000'
  },
  destroyed () {
      document.querySelector('.nav').style.backgroundColor = 'transparent'
  }
}
</script>

<style lang="less" scoped>
.mt_30{
  margin-top: 30px;
}
.caseinfo{
  width: 100%;
  // height: 100%;
  .cotainer{
    max-width: 1200px;
    margin: 0 auto;
    padding: 50px 0;
    width: 100%;
    line-height: 30px;
    h1{
      font-weight: normal;
    }
    h2{
      line-height: 50px;
    }
    .header{
      p{
        color: rgba(51,51,51,1);
        opacity: 0.7;
      }
    }
  }
}
</style>
